<template>
  <v-container v-cloak fluid grid-list-lg>
    <v-layout v-if="loaded">
      <PaymentAlert v-if="!subscribed"/>
    </v-layout>
    <v-layout row wrap>
      <v-flex offset-sm2 sm8 xs12>
        <v-progress-linear v-show="showProgress" :indeterminate="true"/>
        <v-card v-show="!showProgress" class="black--text" width="100%">
          <v-toolbar
              :color="($vuetify.theme.dark) ? 'dark' : 'white'"
              dense>

            <v-toolbar-title>Dental Pockets</v-toolbar-title>

            <v-spacer/>


            <v-btn class="mr-4" icon>
              <v-icon class="mr-2">mdi-clock</v-icon>
              {{ timePassed }}
            </v-btn>


          </v-toolbar>


          <!--                    <v-row align="center" class="text-xs-center pt-3 title font-weight-light" justify="center">Dental-->
          <!--                        Pockets-->
          <!--                    </v-row>-->

          <v-row align="center" class="text-xs-center pt-3 title font-weight-light text--primary" justify="center">
            {{ practiseSubject }} MCQs
          </v-row>

          <v-card-title primary-title>
            <div style="width:100%">
              <div class="title font-weight-light" style="width:100%">
                <div class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="tagColor.color" large>mdi-bookmark</v-icon>
                    </template>
                    <span v-if="tagColor.text">{{ tagColor.text }}</span>
                    <span v-else>Untagged</span>
                  </v-tooltip>
                </div>
                <!--                                <v-card-text>{{item.question}}</v-card-text>-->
              </div>

              <p class="text-xs-center pt-3 title font-weight-light text--primary" style="word-break: normal">
                {{ item.question }}</p>

              <v-radio-group
                  v-if="item.option1"
                  v-model="radios"
                  :mandatory="false"
                  style="width:100%;"
              >
                <v-radio
                    id="RADIO1"
                    :class="{ correct: o1Correct, incorrect: o1Wrong }"
                    class="pa-2"
                    style="width:100%;"
                    value="1"
                >
                  <div slot="label" v-html="item.option1"></div>
                </v-radio>
                <v-radio id="r2"
                         :class="{ correct: o2Correct, incorrect: o2Wrong }" class="pa-2" value="2">
                  <div slot="label" v-html="item.option2"></div>
                </v-radio>
                <v-radio id="r3"
                         :class="{ correct: o3Correct, incorrect: o3Wrong }" class="pa-2" value="3">
                  <div slot="label" v-html="item.option3"></div>
                </v-radio>
                <v-radio :class="{ correct: o4Correct, incorrect: o4Wrong }"
                         class="pa-2" value="4">
                  <div slot="label" v-html="item.option4"></div>
                </v-radio>
              </v-radio-group>


            </div>
          </v-card-title>
          <v-card-actions v-if="item.option1">
            <v-btn v-if="!show" color="pink darken-1 white--text" @click="showAnswer">Show answer
            </v-btn>
            <v-spacer/>
            <v-btn icon @click="showAnswer">

              <v-icon>{{ show ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-btn v-if="!show" color="purple" dark @click="$router.go(-1)">
              <v-icon>mdi-keyboard-backspace</v-icon>
              Go Back
            </v-btn>
          </v-card-actions>

          <v-slide-y-transition>
            <div>
              <v-card-text v-show="show" class="text--primary" v-html="item.description"/>

              <v-row
                  v-show="show"
                  align="center"
                  class="ma-3 pa-3 hidden-md-and-up" justify="center">

                <!--suppress JSUnresolvedVariable -->
                <v-badge
                    :content="item.discussionCount"
                    :value="item.discussionCount  > 0"
                    bordered
                    color="green"
                    icon="mdi-lock"
                    overlap
                >
                  <v-btn block class="ma-2" color="primary" @click="discussMCQ()">
                    <v-icon left>mdi-account-group</v-icon>
                    Discuss
                  </v-btn>
                </v-badge>


              </v-row>


              <v-row
                  v-show="show"
                  align="center"
                  class="ma-3 pa-3 hidden-md-and-up" justify="center">
                <v-btn :loading="loading1" block class="ma-2" color="success" @click="tagMCQ(1)">Know &
                  Next
                  <v-icon dark right>navigate_next</v-icon>
                </v-btn>
                <v-btn :loading="loading2" block class="ma-2" color="warning" @click="tagMCQ(2)">
                  Somewhat Know & Next
                  <v-icon dark right>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn :loading="loading3" block class="ma-2" color="error" @click="tagMCQ(3)">Dont Know
                  & Next
                  <v-icon dark right>mdi-chevron-right</v-icon>
                </v-btn>

                <v-snackbar v-model="snackbar" top>
                  {{ snackbarText }}
                  <v-btn :timeout="timeout" dark @click="snackbar = false">Close</v-btn>
                </v-snackbar>
                <v-flex class="mt-6" xs12>
                  <v-btn block color="pink darken-1 white--text" @click="NextQuestionPlease">
                    NEXT
                    <v-icon dark right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-row>
              <v-row
                  v-show="show"
                  align="center"
                  class="ma-3 pa-3 hidden-sm-and-down" justify="center">


                <!--suppress JSUnresolvedVariable -->
                <v-badge
                    :content="item.discussionCount"
                    :value="item.discussionCount > 0"
                    bordered
                    color="green"
                    icon="mdi-lock"
                    overlap
                >
                  <v-btn class="ma-2" color="primary" @click="discussMCQ()">
                    <v-icon left>mdi-account-group</v-icon>
                    Discuss
                  </v-btn>
                </v-badge>


              </v-row>

              <v-row
                  v-show="show"
                  align="center"
                  class="ma-3 pa-3 hidden-sm-and-down" justify="center">
                <v-btn :loading="loading1" class="ma-2" color="success" @click="tagMCQ(1)">Know & Next
                  <v-icon dark right>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn :loading="loading2" class="ma-2" color="warning" @click="tagMCQ(2)">
                  Somewhat Know & Next
                  <v-icon dark right>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn :loading="loading3" class="ma-2" color="error" @click="tagMCQ(3)">Dont Know &
                  Next
                  <v-icon dark right>mdi-chevron-right</v-icon>
                </v-btn>

                <v-snackbar v-model="snackbar" top>
                  {{ snackbarText }}
                  <v-btn :timeout="timeout" dark @click="snackbar = false">Close</v-btn>
                </v-snackbar>
                <v-flex class="mt-3" xs12>
                  <v-btn color="pink darken-1 white--text" @click="NextQuestionPlease">
                    NEXT
                    <v-icon dark right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-row>

            </div>
          </v-slide-y-transition>
        </v-card>
      </v-flex>
      <v-layout justify="center" row>
        <v-dialog v-model="dialog" max-width="350" persistent>
          <v-card>
            <v-card-title class="title">You have attempted {{ mcqsAllowed }} MCQs</v-card-title>
            <v-card-text>
              Unsubscribed students are allowed to solve only {{ mcqsAllowed }} MCQs per day. To remove this
              restriction
              SUBSCRIBE.
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="green darken-1 white--text" @click="$router.push({'name':'home'})">close
              </v-btn>
              <!--                            <v-btn @click="dialog = false" color="green darken-1 white&#45;&#45;text">close-->
              <!--                        </v-btn>-->
              <v-btn
                  color="indigo darken-1 white--text"
                  @click="$router.push({'name':'subscribe'})"

              >Subscribe
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-dialog v-model="discussionDialog" fullscreen hide-overlay transition="dialog-bottom-transition">

        <v-card full-height full-width width="100%">
          <v-toolbar :color="($vuetify.theme.dark) ? 'dark' : 'white'">
            <v-btn icon @click="closeDiscussionDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Discussions</v-toolbar-title>
          </v-toolbar>

          <v-row ref="discussionList" class="ml-2 mr-2 mt-1" style="overflow-y: auto; height: 84vh;">
            <div v-if="gotDiscussions.length > 0" style="width: 100%;">
              <v-card
                  v-for="(item, index) in gotDiscussions"
                  :key="item.id"
                  class="mr-1"
                  elevation="2"
                  outlined
                  width="100%"
              >

                <div class="mr-1">
                  <v-container class="grey lighten-5 mr-1" fluid>
                    <v-row>
                      <v-col

                          cols="10"

                      >
                        <!--                                            <v-card-text>-->
                        <v-avatar>
                          <!--suppress HtmlUnknownTarget -->
                          <v-img
                              :src="item.img"
                              class="elevation-6"
                          ></v-img>
                        </v-avatar>
                        <span class="subtitle-1 font-weight-light ml-2">{{ item.name }}</span><br><br>
                        <!--suppress RegExpUnnecessaryNonCapturingGroup -->
                        <span class="mt-20 title font-weight-bold"
                              v-html="item.discussion.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span><br><br>
                        <!--suppress JSUnresolvedVariable -->
                        <span class="overline font-weight-light">{{ item.created_at }}</span><br>
                      </v-col>
                      <v-col class="text-center" cols="2">
                        <v-btn :loading="item.loading"
                               icon
                               @click="markFav(item.id, index)">
                          <v-icon v-if="!item.fav">mdi-heart</v-icon>
                          <v-icon v-else color="red">mdi-heart</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-if="parseInt(item.likes) > 0" :key="item.likes"
                               class="text-center">
                            {{ item.likes }}
                          </div>
                        </v-expand-transition>
                        <!--TODO : ADD a button called add description and allow users to add description-->
                      </v-col>
                    </v-row>
                  </v-container>

                </div>


              </v-card>
            </div>
            <div v-else-if="gotDiscussions != null" style="width: 100%; height: 100%;">
              <v-container fill-height fluid>
                <v-row align="center"
                       justify="center">
                  <v-col class="text-center">
                    <v-icon x-large>mdi-monitor-clean</v-icon>
                    <br><br>
                    <p class="headline">No Discussion Found. Be the first one to start a
                      discussion.</p>

                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-row>

          <v-row class="ml-4 mr-4 mb-0">
            <v-flex xs12>
              <hr>
            </v-flex>
            <v-flex class="mt-4 mb-4 pr-1" xs11>
              <v-textarea
                  v-model="discussion"
                  auto-grow
                  filled
                  hide-details
                  label="Add To Discussion"
                  name="input-7-1"
                  rounded
                  rows="1"
              ></v-textarea>

            </v-flex>

            <v-flex class="text-center ma-0" xs1>
              <v-container fill-height fluid>
                <v-row align="center"
                       justify="center">
                  <v-col class="text-center">
                    <v-btn :loading="sendingDiscussion" icon @click="saveDiscussion()">
                      <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>

            </v-flex>
          </v-row>

        </v-card>


      </v-dialog>

      <v-snackbar v-model="snackbarPractise" top>
        Always Tag MCQ according to you. It helps you to revise accordingly.
        <v-btn :timeout="timeoutPractise" dark @click="snackbarPractise = false">Close</v-btn>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
// noinspection ES6PreferShortImport
import {APIService} from "../APIService";
import PaymentAlert from "../components/PaymentAlert";

const apiService = new APIService();


export default {
  metaInfo: {
    title: 'Practise MCQs',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
      }
    ]
  },
  components: {
    PaymentAlert
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    radios(newCount) {
      if (newCount != null) {
        newCount = parseInt(newCount);
        this.increaseQuestionAttemptCounter();
        this.stopTimer();
        let answer = parseInt(this.item.answer);
        if (newCount === answer) {
          if (newCount === 1) {
            this.o1Correct = true;
          } else if (newCount === 2) {
            this.o2Correct = true;
          } else if (newCount === 3) {
            this.o3Correct = true;
          } else if (newCount === 4) {
            this.o4Correct = true;
          }
        } else {
          if (newCount === 1) {
            this.o1Wrong = true;
          } else if (newCount === 2) {
            this.o2Wrong = true;
          } else if (newCount === 3) {
            this.o3Wrong = true;
          } else if (newCount === 4) {
            this.o4Wrong = true;
          }
          this.markCorrect();
        }
        this.show = true;
      }
    }
  },
  computed: {
    getEmail() {
      return this.$store.getters.getUserEmail;
    }
  },
  created() {


    if (localStorage.getItem("isSubscribed") === null) {
      //
      this.$router.push({name: "login"});
    } else {

      //...
      this.subscribed = JSON.parse(localStorage.getItem("isSubscribed"));
    }
  },
  methods: {
    closeDiscussionDialog() {
      this.discussionDialog = false;
      this.discussion = "";

    },
    markFav(did, index) {

      // this.markFavLoading = true;
      apiService
          .markFavUnfav(did)
          .then(data => {
            // eslint-disable-next-line no-console
            // console.log(data);
            if (data.data.status === 201) {

              // eslint-disable-next-line no-console
              // console.log("Im HEre");
              this.gotDiscussions[index]["fav"] = true;
              this.gotDiscussions[index]["likes"] = parseInt(this.gotDiscussions[index]["likes"]) + 1;

              this.markFavLoading = false;

            } else if (data.data.status === 200) {
              // eslint-disable-next-line no-console
              // console.log(data);
              this.markFavLoading = false;
              this.gotDiscussions[index]["fav"] = false;
              this.gotDiscussions[index]["likes"] = parseInt(this.gotDiscussions[index]["likes"]) - 1;


            } else {
              // eslint-disable-next-line no-console
              // console.log(data);
              this.markFavLoading = false;

            }
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            this.markFavLoading = false;

          });

    },
    saveDiscussion() {
      this.sendingDiscussion = true;
      let d = this.item.id;
      let dis = this.discussion;
      apiService
          .saveDiscussionsToServer(d, dis)
          .then(data => {
            // eslint-disable-next-line no-console
            if (data.data.status === 201) {

              // eslint-disable-next-line no-console
              // console.log("Im HEre");
              this.discussion = "";
              this.discussMCQ();
              this.sendingDiscussion = false;

            } else {
              // eslint-disable-next-line no-console
              // console.log(data);
              this.sendingDiscussion = false;

            }
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            this.sendingDiscussion = false;

          });
    },
    discussMCQ() {
      let d = this.item.id;
      // eslint-disable-next-line no-console
      // console.log("Showing Discuss Full Screen Dialog", d);
      apiService
          .getDiscussionsFromServer(d)
          .then(data => {
            // eslint-disable-next-line no-console
            // console.log(data);

            this.$nextTick(() => {
              // noinspection JSUnresolvedVariable
              this.$refs.discussionList.scrollTop = 0;
            });

            if (data.status === 200) {
              this.gotDiscussions = data.data;
              // this.discussMCQ();
            } else {
              // eslint-disable-next-line no-console
              // console.log(data);
            }
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          });

      this.discussionDialog = true;


    },
    stopTimer() {
      clearInterval(this.t);

    },
    restartTimer() {
      clearInterval(this.t);
      this.seconds = "";
      this.minutes = "";
      this.hours = "";
      this.t = null;
      this.timePassed = "00:00";
      this.addSeconds();
    },
    timer() {
      this.t = setTimeout(this.addSeconds, 1000);
    },
    addSeconds() {


      this.seconds++;
      if (this.seconds >= 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes >= 60) {
          this.minutes = 0;
          this.hours++;
        }
      }

      this.timePassed = (this.hours ? (this.hours > 9 ? this.hours : "0" + this.hours) : "00") + ":" + (this.minutes ? (this.minutes > 9 ? this.minutes : "0" + this.minutes) : "00") + ":" + (this.seconds > 9 ? this.seconds : "0" + this.seconds);

      this.timer();

    },
    increaseQuestionAttemptCounter() {
      this.$store.commit("increaseCounter", 1);
      let app = this;
      let id = parseInt(this.item.id);
      let radiosss;
      if (this.showAnswerClicked) {
        radiosss = 9;
      } else {
        radiosss = app.radios;
      }
      let timeTaken = this.timePassed;
      apiService
          .sendAttemptedToServer(id, radiosss, timeTaken)
          .then(data => {
            if (data.status === 201) {
              // console.log(data);
            } else {
              // eslint-disable-next-line no-console
              // console.log(data);
            }
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          });
    },
    getQuestionFromServer() {
      let isSubscribed = false;


      if (localStorage.getItem("isSubscribed") === null) {
        //
        // this.$router.push({name:"login"})
      } else {

        //...
        isSubscribed = JSON.parse(localStorage.getItem("isSubscribed"));
      }

      if (!isSubscribed) {
        let questionsAttempted = this.$store.getters.getTodaysMCQAttempted;
        if (questionsAttempted >= this.mcqsAllowed) {
          // console.log("Not Subscribed");
          this.dialog = true;
          return;
        }
      }
      let tag;
      if (!this.$route.params.tag) {
        tag = 5;
      } else {
        // eslint-disable-next-line no-redeclare
        tag = this.$route.params.tag;
      }

      this.getMCQFromServer(this.$route.params.id, tag);
      this.practiseSubject = this.$store.getters.getPractisingSubject;
    },
    tagMCQ(val) {
      //   this.showProgress = true;
      if (val === 1) {
        this.loading1 = true;
      } else if (val === 2) {
        this.loading2 = true;
      } else if (val === 3) {
        this.loading3 = true;
      }

      const app = this;
      let id = this.item.id;
      apiService
          .tagMCQ(id, val)
          .then(data => {
            if (data.status === 200 || data.status === 201 || data.status === 202) {
              app.snackbarText = "Tag Saved";
              app.snackbar = true;
            } else {
              app.snackbarText = "Error While Saving Tag.";
              app.snackbar = true;
            }
            if (val === 1) {
              app.loading1 = false;
              app.tagColor = {color: "success", text: "Know"};
              this.NextQuestionPlease();
            } else if (val === 2) {
              app.loading2 = false;
              app.tagColor = {color: "warning", text: "Somewhat Know"};
              this.NextQuestionPlease();

            } else if (val === 3) {
              app.loading3 = false;
              app.tagColor = {color: "error", text: "Dont Know"};
              this.NextQuestionPlease();

            }
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            app.snackbarText = "Network Error While Saving Tag.";
            app.snackbar = true;
            app.loading1 = false;
            app.loading2 = false;
            app.loading3 = false;
          });
    },
    NextQuestionPlease() {
      this.snackbar = false;
      this.tagColor = {};

      this.getQuestionFromServer();

      this.radios = null;
      this.show = false;
      this.o1Correct = false;
      this.o2Correct = false;
      this.o3Correct = false;
      this.o4Correct = false;

      this.o1Wrong = false;
      this.o2Wrong = false;
      this.o3Wrong = false;
      this.o4Wrong = false;
    },
    markCorrect() {
      let finalAnswer = parseInt(this.item.answer);
      switch ( finalAnswer ) {
        case 1:
          this.o1Correct = true;
          break;
        case 2:
          this.o2Correct = true;
          break;
        case 3:
          this.o3Correct = true;
          break;
        case 4:
          this.o4Correct = true;
          break;
      }
    },
    getMCQFromServer(val, tag) {
      this.showProgress = true;
      const app = this;
      this.showAnswerClicked = false;

      apiService.getSubjectMCQFromServer(val, tag).then(data => {


        // eslint-disable-next-line no-console
        // console.log(app.item);
        app.item = data;
        app.restartTimer();

        app.showProgress = false;
        app.loaded = true;
        let tag = parseInt(data.tag);
        app.subscribed = data.subscribed;
        // noinspection JSUnresolvedVariable
        let attempts = data.attempts;

        app.$store.commit("setMcqsAttempted", attempts);

        if (app.subscribed === false) {
          if (attempts >= this.mcqsAllowed) {
            // console.log("Not Subscribed");
            app.dialog = true;
            return;
          }
        }

        if (tag === 1) {
          app.tagColor = {color: "success", text: "Know"};
        } else if (tag === 2) {
          app.tagColor = {color: "warning", text: "Somewhat Know"};
        } else if (tag === 3) {
          app.tagColor = {color: "error", text: "Dont Know"};
        }
      });
    },
    // PractiseThisSubject(val) {
    //     this.$router.push({name: "practise", params: {val}});
    // },
    showAnswer() {
      this.radios = this.item.answer;
      this.show = !this.show;
      this.showAnswerClicked = true;
    }
  },
  mounted: function () {
    this.$nextTick(function () {

      if (localStorage.getItem("uid") === null) {
        //
        this.$router.push({name: "login"})
      } else {
        // Code that will run only after the
        // entire view has been rendered
        this.getQuestionFromServer();
      }


    });
  },
  beforeDestroy() {
    if (this.t) {
      clearInterval(this.t);
      this.t = null;
    }
    this.$store.commit("SetPractiseSubject", "");
  },
  data() {
    return {
      mcqsAllowed: 20,
      seconds: "",
      minutes: "",
      hours: "",
      t: null,
      timePassed: "00:00",

      show: false,
      item: {
        id: "261",
        question: "Heme is converted to bilirubin mainly in : (AIPG-05)",
        option1: "Kidney ",
        option2: "Liver",
        option3: "Spleen",
        option4: "Bone Marrow",
        answer: "3",
        description:
            "<p>[Satyanarayana 3rd & th ed 214, 215]</p><p>Heme is converted to bilirubin by the macrophages of reticuloendothelial system. (Mostly spleen, also liver and bone marrow)</p>",
        link: "",
        image_res: "",
        video_res: "",
        tag: "1"
      },
      dialog: false,
      subscribed: false,
      showProgress: true,
      practiseSubject: "",
      showAnswerClicked: false,
      loaded: false,

      snackbarPractise: true,
      timeoutPractise: 10000,
      tagColor: {},
      snackbar: false,
      snackbarText: "",
      timeout: 5000,

      loading1: false,
      loading2: false,
      loading3: false,

      radios: null,
      o1Correct: false,
      o2Correct: false,
      o3Correct: false,
      o4Correct: false,

      o1Wrong: false,
      o2Wrong: false,
      o3Wrong: false,
      o4Wrong: false,


      discussionDialog: false,
      discussion: "",
      sendingDiscussion: false,
      gotDiscussions: [],
      markFavLoading: false,

    };
  }
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.correct {
  border: 3px solid green;
  /* padding: 2px; */
  margin: 2px;
}

.incorrect {
  border: 3px solid red;
  /* padding: 2px; */
  margin: 2px;
}

>>> .v-input__control {
  width: 100%;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

>>> .v-label {
  width: 100% !important;
  height: auto !important;
}
</style>
